"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MakeTree = MakeTree;
exports.default = Summary;
const react_1 = __importStar(require("react"));
function MakeTree({ list, level, }) {
    const first = list[0];
    if (!first) {
        return null;
    }
    const newList = list.slice(1);
    const curLevel = parseInt(first.tagName.charAt(1), 10);
    if (level === curLevel) {
        const findNext = newList.findIndex((e) => parseInt(e.tagName.charAt(1), 10) === curLevel);
        if (findNext !== -1) {
            return (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(MakeTree, { list: list.slice(0, findNext + 1), level: level }),
                react_1.default.createElement(MakeTree, { list: list.slice(findNext + 1), level: level })));
        }
        return (react_1.default.createElement("li", { key: first.textContent },
            react_1.default.createElement("b", null,
                react_1.default.createElement("a", { href: `#${first.id}` }, first.textContent)),
            react_1.default.createElement("ol", null,
                react_1.default.createElement(MakeTree, { list: list.slice(1), level: level + 1 }))));
    }
    return null;
}
function Summary() {
    const el = (0, react_1.useMemo)(() => {
        const main = document.querySelector('.content-block .md-preview');
        if (!main) {
            return [];
        }
        return Array.from(main.childNodes).filter((e) => /[hH][1-9]/.test(e.nodeName));
    }, []);
    return (react_1.default.createElement("div", null,
        react_1.default.createElement("h1", null, "Inhaltsverzeichnis"),
        react_1.default.createElement("ol", null,
            react_1.default.createElement(MakeTree, { list: el, level: 1 }))));
}
