"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = setBrowserURL;
function setBrowserURL(url) {
    const { title } = document;
    if (window.history.replaceState) {
        // prevents browser from storing history with each change:
        window.history.replaceState({ pageTitle: title }, title, url);
    }
    else {
        window.history.pushState({ pageTitle: title }, title, url);
    }
}
