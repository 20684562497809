"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ImgNavigator;
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
function ImgNavigator({ imgSrc, className, }) {
    const [index, setIndex] = (0, react_1.useState)(0);
    const cur = (0, react_1.useMemo)(() => imgSrc[index], [imgSrc, index]);
    const size = (0, react_1.useMemo)(() => Array.from(new Array(imgSrc.length).keys()), [imgSrc]);
    return (react_1.default.createElement(react_components_1.Grid, { flex: true, className: [className, 'img-navigator'] },
        react_1.default.createElement("img", { src: `/api/attachment/e/${cur}`, alt: `carosell_${index}` }),
        react_1.default.createElement(react_components_1.Grid, { flex: true, flexRow: true, className: "bot-nav", hCenter: true }, size.map((i) => (react_1.default.createElement("div", { onClick: () => setIndex(i) }, i === index ? react_1.default.createElement(react_components_1.IORadioButtonOnOutline, null) : react_1.default.createElement(react_components_1.IORadioButtonOff, null))))),
        index !== 0 && (react_1.default.createElement(react_components_1.Grid, { flex: true, className: "l-nav", vCenter: true, onClick: () => setIndex(index - 1) },
            react_1.default.createElement(react_components_1.IOChevronBack, null))),
        index + 1 < size.length && (react_1.default.createElement(react_components_1.Grid, { flex: true, className: "r-nav", vCenter: true, onClick: () => setIndex(index + 1) },
            react_1.default.createElement(react_components_1.IOChevronForward, null)))));
}
