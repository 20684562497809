"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaticURL = void 0;
exports.useBaseUrl = useBaseUrl;
const react_1 = require("react");
const react_components_1 = require("@grandlinex/react-components");
var StaticURL;
(function (StaticURL) {
    StaticURL["MAP_TILE_PATH"] = "/api/map/t/";
    StaticURL["MAP_PIN_PATH"] = "/api/map/i/";
    StaticURL["RESOURCE_ICON"] = "/api/resource/icon/e/";
})(StaticURL || (exports.StaticURL = StaticURL = {}));
// eslint-disable-next-line import/prefer-default-export
function useBaseUrl(selPath) {
    const meta = (0, react_1.useMemo)(() => {
        return (0, react_components_1.getDocumentMeta)('REACT_APP_VERSION') === '0.0.0';
    }, []);
    return (0, react_1.useMemo)(() => {
        if (meta) {
            return `http://localhost:9257${selPath}`;
        }
        return selPath;
    }, [meta, selPath]);
}
