"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectCanWrite = exports.selectIsAdmin = exports.selectUser = exports.selectConnected = exports.selectEndpoint = exports.selectToken = exports.selectLogin = exports.selectIsNew = exports.selectUserId = exports.selectUserName = exports.selectIsInitG = exports.selectTrans = exports.setTranslation = exports.setAppStore = exports.setInit = exports.appState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_components_1 = require("@grandlinex/react-components");
const initialAppEnv = {
    lang: react_components_1.LocalStorage.load('lang', 'de'),
    translation: null,
    isNew: false,
    login: false,
    connected: false,
    userName: '',
    userId: '',
    token: '',
    endpoint: '',
    init: false,
    user: null,
};
let curTrans = null;
exports.appState = (0, toolkit_1.createSlice)({
    name: 'app',
    initialState: initialAppEnv,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setLogin: (state, action) => {
            state.login = action.payload;
        },
        setIsNew: (state, action) => {
            state.isNew = action.payload;
        },
        setInit: (state, action) => {
            state.init = action.payload;
        },
        setAppStore: (state, action) => {
            const keys = Object.keys(action.payload);
            keys.forEach((key) => {
                state[key] = action.payload[key];
            });
        },
        setTranslation: (state, action) => {
            state.translation = action.payload;
            curTrans = new react_components_1.GLang(action.payload);
        },
    },
});
_a = exports.appState.actions, exports.setInit = _a.setInit, exports.setAppStore = _a.setAppStore, exports.setTranslation = _a.setTranslation;
const selectTrans = (state) => {
    if (!curTrans) {
        curTrans = new react_components_1.GLang(state.app.translation);
    }
    return curTrans;
};
exports.selectTrans = selectTrans;
const selectIsInitG = (state) => state.app.init;
exports.selectIsInitG = selectIsInitG;
const selectUserName = (state) => state.app.userName;
exports.selectUserName = selectUserName;
const selectUserId = (state) => state.app.userId;
exports.selectUserId = selectUserId;
const selectIsNew = (state) => state.app.isNew;
exports.selectIsNew = selectIsNew;
const selectLogin = (state) => state.app.login;
exports.selectLogin = selectLogin;
const selectToken = (state) => state.app.token;
exports.selectToken = selectToken;
const selectEndpoint = (state) => state.app.endpoint;
exports.selectEndpoint = selectEndpoint;
const selectConnected = (state) => state.app.connected;
exports.selectConnected = selectConnected;
const selectUser = (state) => state.app.user;
exports.selectUser = selectUser;
const selectIsAdmin = (state) => state.app.user?.admin ?? false;
exports.selectIsAdmin = selectIsAdmin;
const selectCanWrite = (state) => state.app.user?.allow_write ?? false;
exports.selectCanWrite = selectCanWrite;
