"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = MapPinElement;
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const react_toastify_1 = require("react-toastify");
const hooks_1 = require("@/lib/hooks");
const store_1 = require("@/store");
function MapPinElement({ el, reload, }) {
    const t = (0, store_1.useTranslation)();
    const [max, setMax] = (0, react_1.useState)(false);
    const imgResolve = (0, hooks_1.useBaseUrl)(hooks_1.StaticURL.MAP_PIN_PATH);
    /* const context = useGlobalContext(); */
    const [edit, setEdit] = (0, react_1.useState)(false);
    const [desc, setDesc] = (0, react_1.useState)(el.icon_name || '');
    if (!max) {
        return (react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true, className: "map-pin-element", flexSpaceB: true, hCenter: true, onClick: () => {
                setMax(true);
            } },
            react_1.default.createElement(react_components_1.Tooltip, { text: el.file },
                react_1.default.createElement("img", { width: el.icon?.width ?? 50, height: el.icon?.height ?? 70, src: imgResolve + el.e_id, alt: el.file, loading: "lazy" }))));
    }
    return (react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, className: "pin-element", flexSpaceB: true },
        react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true, flexSpaceB: true, gap: 4 },
            react_1.default.createElement("div", { className: "glx-bold" }, "Dateiname:"),
            react_1.default.createElement("div", null, el.file),
            react_1.default.createElement("div", { className: "glx-bold" }, "Beschreibung:"),
            edit ? (react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, flexSpaceB: true },
                react_1.default.createElement(react_components_1.Grid, { grow: 1 },
                    react_1.default.createElement("input", { placeholder: "Bild beschreibung", type: "text", value: desc, onChange: (e) => setDesc(e.target.value) })),
                react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true },
                    react_1.default.createElement(react_components_1.IconButton, { onClick: async () => {
                            /*
                                              await context.patchAttachmentElement(el.e_id, {
                                              file_description: desc,
                                            }); */
                            setEdit(false);
                            reload();
                        } },
                        react_1.default.createElement(react_components_1.IOCheckmark, null)),
                    react_1.default.createElement(react_components_1.IconButton, { onClick: () => setEdit(false) },
                        react_1.default.createElement(react_components_1.IOClose, null))))) : (react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, flexSpaceB: true },
                el.icon_name,
                react_1.default.createElement(react_components_1.IconButton, { onClick: () => setEdit(true), toolTip: {
                        text: 'Name bearbeiten',
                    } },
                    react_1.default.createElement(react_components_1.IOPencil, null))))),
        react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true, gap: 12, vCenter: true },
            react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, className: "glx-bold", vCenter: true, gap: 8 },
                react_1.default.createElement(react_components_1.Tooltip, { className: "glx-pointer", text: el.e_id, position: "bottom" }, "ID"),
                react_1.default.createElement(react_components_1.IconButton, { icon: "IOCopy", onClick: () => {
                        (0, react_components_1.copyToClipboard)(el.e_id).then(() => {
                            react_toastify_1.toast.dark(t.get('page.admin.coppy.id'));
                        });
                    }, toolTip: {
                        text: 'ID kopieren',
                    } }),
                react_1.default.createElement(react_components_1.IconButton, { icon: "IOContractOutline", onClick: () => {
                        setMax(false);
                    }, toolTip: {
                        text: 'Minimieren',
                    } })),
            react_1.default.createElement("img", { loading: "lazy", width: el.icon?.width ?? 40, height: el.icon?.height ?? 54, src: imgResolve + el.e_id, alt: el.file }))));
}
