"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = DevMarker;
const react_1 = __importStar(require("react"));
const react_leaflet_1 = require("react-leaflet");
const react_components_1 = require("@grandlinex/react-components");
const leaflet_1 = require("leaflet");
function DevMarker() {
    const map = (0, react_leaflet_1.useMap)();
    const [lat, setLat] = (0, react_1.useState)(map.getCenter().lat);
    const [lng, setLng] = (0, react_1.useState)(map.getCenter().lng);
    const [shown, setShown] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const center = map.getCenter();
        setLat(center.lat);
        setLng(center.lng);
    }, [shown]);
    const icon = (0, react_1.useMemo)(() => {
        return new leaflet_1.Icon({
            iconUrl: '/static/marker-icon.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [0, -41],
            className: (0, react_components_1.cnx)('dev-marker'),
        });
    }, []);
    (0, react_components_1.useKeyListener)([
        {
            key: {
                primary: 'alt',
                secondary: 'KeyI',
            },
            action: () => {
                setShown(!shown);
            },
        },
    ]);
    if (!shown) {
        return null;
    }
    return (react_1.default.createElement(react_leaflet_1.Marker, { draggable: true, icon: icon, position: [lat, lng], eventHandlers: {
            dragend: (e) => {
                setLat(e.target.getLatLng().lat);
                setLng(e.target.getLatLng().lng);
            },
        } },
        react_1.default.createElement(react_leaflet_1.Popup, null,
            react_1.default.createElement("div", { onClick: () => (0, react_components_1.copyToClipboard)(lat.toString()) },
                "lat: ",
                `${lat}`,
                ","),
            react_1.default.createElement("div", { onClick: () => (0, react_components_1.copyToClipboard)(lng.toString()) },
                "lng: ",
                `${lng}`),
            react_1.default.createElement("button", { onClick: () => (0, react_components_1.copyToClipboard)(JSON.stringify({
                    lat,
                    lng,
                })) }, "Copy"))));
}
