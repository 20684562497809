"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const unist_util_visit_1 = require("unist-util-visit");
class Extractor {
    static extractTextCodeBlocks(keyWord, ext) {
        return (tree) => {
            const visitor = (node, index, parent) => {
                const { value } = node;
                const regText = `!${keyWord}#[a-zA-Z0-9-]*#[^ !]*!`;
                const match = new RegExp(regText, 'gm').test(value);
                const exec = value.matchAll(new RegExp(regText, 'gm'));
                if (match && exec) {
                    let out = value;
                    for (const m of exec) {
                        const n = m[0].substring(1, m[0].length - 1);
                        const comp = ext(n.split('#'));
                        if (comp) {
                            out = out.replace(m, comp);
                        }
                    }
                    parent.children.splice(index, 1, {
                        type: 'html',
                        value: out,
                    });
                }
            };
            (0, unist_util_visit_1.visit)(tree, 'text', visitor);
            (0, unist_util_visit_1.visit)(tree, 'html', visitor);
        };
    }
}
exports.default = Extractor;
