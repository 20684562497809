"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = MediaElement;
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const react_toastify_1 = require("react-toastify");
const ImgUtil_1 = require("@/utils/ImgUtil");
const GlobalContext_1 = require("@/context/GlobalContext");
const store_1 = require("@/store");
function MediaElement({ el, reload, }) {
    const t = (0, store_1.useTranslation)();
    const imgResolve = (0, ImgUtil_1.useResolveImage)();
    const context = (0, GlobalContext_1.useGlobalContext)();
    const [edit, setEdit] = (0, react_1.useState)(false);
    const [desc, setDesc] = (0, react_1.useState)(el.file_description || '');
    const src = (0, react_1.useMemo)(() => imgResolve(`/api/attachment/e/${el.e_id}`), [imgResolve, el.e_id]);
    const medComp = (0, react_1.useMemo)(() => {
        if (el.mine_type.startsWith('image')) {
            return react_1.default.createElement("img", { src: src, alt: el.file_name });
        }
        if (el.mine_type.startsWith('video')) {
            return react_1.default.createElement("video", { src: src, controls: true, muted: true });
        }
        if (el.mine_type.startsWith('audio')) {
            return react_1.default.createElement("audio", { src: src, controls: true });
        }
        return null;
    }, [el.file_name, el.mine_type, src]);
    return (react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true, className: "media-element", flexSpaceB: true },
        react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true, flexSpaceB: true, gap: 4 },
            react_1.default.createElement("div", { className: "glx-bold" }, "Dateiname:"),
            react_1.default.createElement("div", null, el.file_name),
            react_1.default.createElement("div", { className: "glx-bold" }, "Beschreibung:"),
            edit ? (react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, flexSpaceB: true },
                react_1.default.createElement(react_components_1.Grid, { grow: 1 },
                    react_1.default.createElement("input", { placeholder: "Bild beschreibung", type: "text", value: desc, onChange: (e) => setDesc(e.target.value) })),
                react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true },
                    react_1.default.createElement(react_components_1.IconButton, { onClick: async () => {
                            await context.patchAttachmentElement(el.e_id, {
                                file_description: desc,
                            });
                            setEdit(false);
                            reload();
                        } },
                        react_1.default.createElement(react_components_1.IOCheckmark, null)),
                    react_1.default.createElement(react_components_1.IconButton, { onClick: () => setEdit(false) },
                        react_1.default.createElement(react_components_1.IOClose, null))))) : (react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, flexSpaceB: true },
                el.file_description ?? 'Keine Beschreibung',
                react_1.default.createElement(react_components_1.IconButton, { onClick: () => setEdit(true), toolTip: {
                        text: 'Beschreibung bearbeiten',
                    } },
                    react_1.default.createElement(react_components_1.IOPencil, null)))),
            react_1.default.createElement("div", { className: "glx-bold" }, "ID:"),
            react_1.default.createElement("div", { className: "glx-pointer glx-no-select", onClick: () => {
                    (0, react_components_1.copyToClipboard)(el.e_id).then(() => {
                        react_toastify_1.toast.dark(t.get('page.admin.coppy.id'));
                    });
                } },
                react_1.default.createElement("i", null, el.e_id)),
            react_1.default.createElement("div", { className: "glx-bold" }, "Gr\u00F6\u00DFe & Typ:"),
            react_1.default.createElement("div", null,
                (el.file_size / 1024 / 1024).toFixed(2),
                " MB - ",
                el.mine_type),
            react_1.default.createElement("div", { className: "glx-bold" }, "Datum:"),
            react_1.default.createElement("div", null, el.created),
            medComp),
        react_1.default.createElement(react_components_1.Button, { onClick: () => {
                react_toastify_1.toast.dark(react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true },
                    react_1.default.createElement("div", null, "Wirklich l\u00F6schen?"),
                    react_1.default.createElement("div", null,
                        react_1.default.createElement(react_components_1.IconButton, { onClick: async () => {
                                const rr = await context.deleteAttachmentElement(el.e_id);
                                if (rr.success) {
                                    react_toastify_1.toast.dark(t.get('page.admin.delete.success'));
                                }
                                else {
                                    react_toastify_1.toast.dark(t.get('page.admin.delete.error'));
                                }
                                reload();
                            } },
                            react_1.default.createElement(react_components_1.IOCheckmark, null)),
                        react_1.default.createElement(react_components_1.IconButton, { onClick: () => { } },
                            react_1.default.createElement(react_components_1.IOClose, null)))), {
                    autoClose: false,
                });
            } }, "L\u00F6schen")));
}
