"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResolveImage = useResolveImage;
const GlobalContext_1 = require("@/context/GlobalContext");
// eslint-disable-next-line import/prefer-default-export
function useResolveImage() {
    const context = (0, GlobalContext_1.useGlobalContext)();
    return (url) => {
        if (url.startsWith('/api/media') || url.startsWith('/api/attachment')) {
            return `${context.api}${url}`;
        }
        return url;
    };
}
