"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = usePinHook;
const leaflet_1 = require("leaflet");
const react_1 = require("react");
const react_components_1 = require("@grandlinex/react-components");
const MapContext_1 = require("@/component/map/MapContext");
function usePinHook(pin) {
    const { activePin, done, typeMap, iconMap, pinPath } = (0, MapContext_1.useMapContext)();
    const type = (0, react_1.useMemo)(() => {
        return typeMap.get(pin.map_type);
    }, [pin.map_type, typeMap]);
    const isActive = (0, react_1.useMemo)(() => activePin?.e_id === pin.e_id, [activePin, pin]);
    const isDone = (0, react_1.useMemo)(() => done.includes(pin.e_id), [done, pin]);
    const ico = (0, react_1.useMemo)(() => {
        if (!type) {
            return new leaflet_1.Icon({
                iconUrl: '/static/marker-icon.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [0, -41],
                className: (0, react_components_1.cnx)([isActive, 'active-marker'], [isDone, 'active-marker--done']),
            });
        }
        const { icon } = type;
        const pinIco = iconMap.get(icon);
        if (!pinIco) {
            return new leaflet_1.Icon({
                iconUrl: pinPath + icon,
                iconSize: [33, 44],
                iconAnchor: [16, 44],
                className: (0, react_components_1.cnx)([isActive, 'active-marker'], [isDone, 'active-marker--done']),
            });
        }
        const { width, height, anchorX, anchorY, popupX, popupY } = pinIco;
        return new leaflet_1.Icon({
            iconUrl: pinPath + icon,
            iconSize: [width, height],
            iconAnchor: [anchorX, anchorY],
            popupAnchor: popupX && popupY ? [popupX, popupY] : undefined,
            className: (0, react_components_1.cnx)([isActive, 'active-marker'], [isDone, 'active-marker--done']),
        });
    }, [iconMap, isActive, isDone, pinPath, type]);
    return {
        ico,
        isActive,
        isDone,
    };
}
