"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransFcRaw = TransFcRaw;
exports.TransFc = TransFc;
exports.default = TransComp;
const store_1 = require("@/store");
function TransFcRaw({ sKey, el, children, fallback, anyLang, langCode, }) {
    if (el && langCode === 'de' && el[`${sKey}_de`]) {
        return el[`${sKey}_de`];
    }
    if (el && el[sKey]) {
        return el[sKey];
    }
    if (el && anyLang && el[`${sKey}_de`]) {
        return el[`${sKey}_de`];
    }
    if (children) {
        return children;
    }
    if (fallback) {
        return 'Unknown';
    }
    return null;
}
function TransFc({ sKey, el, children, fallback, anyLang, t, }) {
    return TransFcRaw({
        sKey,
        el,
        children,
        fallback,
        anyLang,
        langCode: t.code,
    });
}
function TransComp({ el, sKey, children, fallback, }) {
    const t = (0, store_1.useTranslation)();
    return TransFc({ sKey, el, children, fallback, t });
}
