"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMidpoint = getMidpoint;
function getMidpoint(coordinates) {
    function toRadians(degrees) {
        return (degrees * Math.PI) / 180;
    }
    function toDegrees(radians) {
        return (radians * 180) / Math.PI;
    }
    let x = 0;
    let y = 0;
    let z = 0;
    coordinates.forEach((coord) => {
        const lat = toRadians(coord.lat);
        const lon = toRadians(coord.lng);
        x += Math.cos(lat) * Math.cos(lon);
        y += Math.cos(lat) * Math.sin(lon);
        z += Math.sin(lat);
    });
    const total = coordinates.length;
    x /= total;
    y /= total;
    z /= total;
    const centralLongitude = Math.atan2(y, x);
    const centralSquareRoot = Math.sqrt(x * x + y * y);
    const centralLatitude = Math.atan2(z, centralSquareRoot);
    return {
        lat: toDegrees(centralLatitude),
        lng: toDegrees(centralLongitude),
    };
}
