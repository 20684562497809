"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultHandler = void 0;
const react_components_1 = require("@grandlinex/react-components");
const elschnagoo_eu_con_1 = __importStar(require("@awesomegroup/elschnagoo.eu-con"));
class WebFHandler extends elschnagoo_eu_con_1.default {
    env;
    name;
    constructor(endpoint) {
        super({ con: elschnagoo_eu_con_1.FetchCon, endpoint: endpoint ?? '' });
        this.name = 'web';
        this.env = {
            lang: react_components_1.LocalStorage.load('lang', 'de'),
            translation: null,
            login: false,
            isNew: false,
            userName: '',
            userId: '',
            token: '',
            endpoint: endpoint ?? '',
            init: true,
            connected: false,
            user: null,
        };
    }
    getName() {
        return 'web';
    }
    async logOut() {
        this.env.token = '';
        window.localStorage.removeItem('token');
        window.location.reload();
    }
    async openExternalConfig(conf) {
        if (window.location.search.includes('m=true')) {
            window.location = `intent:${conf.url}#Intent;end`;
        }
        else {
            window.open(conf.url, conf.external ? '_blank' : '_self');
        }
    }
    async preloadEnv() {
        return this.env;
    }
}
exports.default = WebFHandler;
const defaultHandler = new WebFHandler();
exports.defaultHandler = defaultHandler;
const c_name = (0, react_components_1.getDocumentMeta)('REACT_C_NAME');
defaultHandler.disconnected = false;
const tk = react_components_1.LocalStorage.load('token', 'GUEST');
if (tk !== 'GUEST') {
    defaultHandler.authorization = `Bearer ${tk}`;
}
else {
    defaultHandler.authorization = tk;
}
if (c_name === 'DEV') {
    defaultHandler.api = 'http://localhost:9257';
    //  defaultHandler.api = 'https://elschnagoo.eu';
}
else {
    defaultHandler.api = window.location.origin || '';
}
