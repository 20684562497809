"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ResTooltip;
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const GlobalContext_1 = require("@/context/GlobalContext");
const hooks_1 = require("@/lib/hooks");
const ResourceMetaData_1 = require("@/component/ResourceMetaData");
const TransComp_1 = require("@/component/map/TransComp");
function ResTooltip({ rid, icon, }) {
    const lang = (0, react_1.useMemo)(() => react_components_1.LocalStorage.load('lang', 'de'), []);
    const context = (0, GlobalContext_1.useGlobalContext)();
    const resImgUrl = (0, hooks_1.useBaseUrl)(hooks_1.StaticURL.RESOURCE_ICON);
    const [resData] = (0, react_components_1.useQData)(async () => {
        const { data } = await context.getResourceData(rid);
        return data;
    });
    if (!resData) {
        return react_1.default.createElement("div", null, "...");
    }
    return (react_1.default.createElement(react_components_1.Tooltip, { text: react_1.default.createElement("div", { className: "res-tooltip--body" },
            react_1.default.createElement(react_components_1.Grid, null,
                react_1.default.createElement("img", { height: "120px", width: "100%", src: resImgUrl + resData.res_icon, alt: "", style: { objectFit: 'contain' } }),
                react_1.default.createElement("div", { style: {
                        color: resData.color,
                        textDecoration: 'underline',
                    } },
                    react_1.default.createElement("b", null,
                        react_1.default.createElement(TransComp_1.TransFcRaw, { sKey: "res_name", el: resData, anyLang: true, langCode: lang })))),
            react_1.default.createElement("div", null,
                "Type: ",
                resData.res_type),
            react_1.default.createElement(ResourceMetaData_1.ResourceMetaElement, { el: resData.meta })) },
        react_1.default.createElement("a", { href: `/?page=resource-view&e_id=${rid}`, className: "res-tooltip", style: {
                color: resData.color,
            } },
            icon && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement("img", { height: "24px", width: "24px", src: resImgUrl + resData.res_icon, alt: "" }),
                ' ')),
            react_1.default.createElement(TransComp_1.TransFcRaw, { sKey: "res_name", el: resData, anyLang: true, langCode: lang }))));
}
